import React from "react";
import Header from "../components/header";

export default class Index extends React.Component<any, any> {

	constructor(props) {
		super(props);
	}

	public render() {

		return(
			<React.Fragment>
				<Header />
			</React.Fragment>
		);

	}
}
